@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
}


@font-face {
  font-family: 'Cera Pro Black';
  src: url('https://assets.starkdefi.com/fonts/cera-pro/CeraPro-Black.woff2') format('woff2'),
    url('https://assets.starkdefi.com/fonts/cera-pro/CeraPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro Black Italic';
  src: url('https://assets.starkdefi.com/fonts/cera-pro/CeraPro-BlackItalic.woff2') format('woff2'),
    url('https://assets.starkdefi.com/fonts/cera-pro/CeraPro-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro Bold';
  src: url('https://assets.starkdefi.com/fonts/cera-pro/CeraPro-Bold.woff2') format('woff2'),
    url('https://assets.starkdefi.com/fonts/cera-pro/CeraPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro Medium';
  src: url('https://assets.starkdefi.com/fonts/cera-pro/CeraPro-Medium.woff2') format('woff2'),
    url('https://assets.starkdefi.com/fonts/cera-pro/CeraPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro Regular Italic';
  src: url('https://assets.starkdefi.com/fonts/cera-pro/CeraPro-Italic.woff2') format('woff2'),
    url('https://assets.starkdefi.com/fonts/cera-pro/CeraPro-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro Light';
  src: url('https://assets.starkdefi.com/fonts/cera-pro/CeraPro-Light.woff2') format('woff2'),
    url('https://assets.starkdefi.com/fonts/cera-pro/CeraPro-Light.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.font-\[300\] {
  font-family: "Cera Pro Light";
}

.font-semibold {
  font-family: "Cera Pro Bold";
}

.font-bold {
  font-family: "Cera Pro Black";
}


html,
body {
  margin: 0;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-family: "Cera Pro", sans-serif;
  font-display: swap;
  background-color: #060606;
}


/* remove arrows on number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}


* {
  scrollbar-color: #C21010 #fff;
}

a {
  -webkit-tap-highlight-color: transparent;
}

.no-scrollbar {
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

input,
textarea {
  outline: 0;
  border: 0;
}

textarea {
  resize: none;
}

input:focus,
textarea:focus {
  outline: 0;
  border: 0;
}

.search-input {
  background-color: #1B1B1B;
  border-radius: 7px;
}

.hr-color {
  background: rgba(98, 100, 106, 0.25);
}

table {
  border-collapse: separate;
  border-spacing: 0 5px;
}


:root {
  --tooltip-text-color: white;
  --tooltip-background-color: #383838;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}

/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1.5);
}

/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}

.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner>div {
  background-color: #333;
  height: 100%;
  width: 6px;
  margin-right: 1px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4)
  }

  20% {
    -webkit-transform: scaleY(1.0)
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}